import {
    Swiper,
    Navigation,
    Pagination,
    Autoplay,
    EffectFade,
} from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const banners = document.querySelectorAll(".banner-desktop");
const mobileBannerNamePagination = ["", "", "", "", ""];
const bannerNamePagination = [];

banners.forEach(banner => {
    bannerNamePagination.push(banner.dataset.link);
});

export default () => {
    let swiper = new Swiper(".gallery-modal-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    let banner = new Swiper(".banner__slider", {
        watchOverflow: true,
        pagination: {
            el: ".banner-pagination",
            clickable: true,
            renderBullet: function(index, className) {
                let slideText =
                    document
                        .querySelectorAll(".swiper-slide")
                        [index]?.querySelector("h3")
                        ?.getAttribute("data-text") || `Slide ${index + 1}`;

                return `
                    <span class="${className}"> 
                        <div class="bullet-text">${slideText}</div>
                        <div class="progress-bar"></div>
                    </span>
                `;
            },
        },
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        effect: "fade",
        on: {
            init: function() {
                const firstSlide = this.slides[this.activeIndex];
                firstSlide.querySelector("img").classList.add("zoom-in");
            },
            slideChange: function() {
                const previousSlide = this.slides[this.previousIndex];
                previousSlide.querySelector("img").classList.remove("zoom-in");

                const currentSlide = this.slides[this.activeIndex];
                currentSlide.querySelector("img").classList.add("zoom-in");
            },
            slideChangeTransitionStart: function() {
                document.querySelectorAll(".progress-bar").forEach(bar => {
                    bar.style.width = "0%";
                    bar.style.transition = "none";
                });

                setTimeout(() => {
                    document
                        .querySelectorAll(
                            ".swiper-pagination-bullet-active .progress-bar"
                        )
                        .forEach(bar => {
                            bar.style.width = "100%";
                            bar.style.transition = `width 6s linear`;
                        });
                }, 50);
            },
        },
        breakpoints: {
            1199: {
                pagination: {
                    el: ".banner-pagination",
                    clickable: true,
                    renderBullet: function(index, className) {
                        let slideText =
                            document
                                .querySelectorAll(".swiper-slide")
                                [index]?.querySelector("h3")
                                ?.getAttribute("data-text") ||
                            `Slide ${index + 1}`;

                        return `
                            <span class="${className}"> 
                                <div class="bullet-text">${slideText}</div>
                                <div class="progress-bar"></div>
                            </span>
                        `;
                    },
                },
            },
        }, 
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
    });
    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let phrasesSlider = new Swiper(".phrases__slider", {
        watchOverflow: true,
        autoplay: {
            delay: 8000,
        },
        navigation: {
            nextEl: ".phrases-button-next",
            prevEl: ".phrases-button-prev",
        },
    }); 
};
