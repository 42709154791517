import { Swiper, Navigation, Pagination, Autoplay } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay]);

export default () => {
    let swiper = new Swiper(".gallery-modal-slider", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: ".banner-button-next",
            prevEl: ".banner-button-prev",
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let phrasesSlider = new Swiper(".phrases__slider", {
        watchOverflow: true,
        autoplay: {
            delay: 8000,
        },
    });

     let bannerHome = new Swiper(".banner__home", {
        watchOverflow: true,
        speed: 2000,
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".home-pagination",
            type: "bullets",
            clickable: true,
        },
    });
};
