export default function accordion() {
    const accordionItems = document.querySelectorAll(".js-accordion");

    accordionItems.forEach(item => {
        const accordionHeader = item.querySelector(".js-accordion-header");
        const accordionContent = item.querySelector(".js-accordion-content");
        const accordionIcon = item.querySelector(".js-accordion-header i");

        accordionHeader.addEventListener("click", () => {
             
            accordionItems.forEach(otherItem => {
                if (otherItem !== item && otherItem.classList.contains("is-active")) {
                    const otherHeader = otherItem.querySelector(".js-accordion-header");
                    const otherContent = otherItem.querySelector(".js-accordion-content");
                    const otherIcon = otherItem.querySelector(".js-accordion-header i");
                    const otherSvg = otherItem.querySelector(".js-accordion-header svg");

                    otherItem.classList.remove("is-active");
                    otherHeader.classList.remove("is-active");
                    otherContent.classList.remove("is-active");
                    otherIcon.classList.remove("fa-minus");
                    otherIcon.classList.add("fa-plus");
                }
            });

            const isActive = item.classList.contains("is-active");

            if (isActive) {
                item.classList.remove("is-active");
                accordionHeader.classList.remove("is-active");
                accordionContent.classList.remove("is-active");
                accordionIcon.classList.remove("fa-minus");
                accordionIcon.classList.add("fa-plus");

            } else {
                item.classList.add("is-active");
                accordionHeader.classList.add("is-active");
                accordionContent.classList.add("is-active");
                accordionIcon.classList.remove("fa-plus");
                accordionIcon.classList.add("fa-minus");
            }
        });
    });
}
